@font-face {
    font-family: "Gotham-Light";
    src: url("../fonts/Gotham-Light.woff") format("woff"),
        url("../fonts/Gotham-Light.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Gotham-Bold";
    src: url("../fonts/Gotham-Bold.woff") format("woff"),
        url("../fonts/Gotham-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: bold;
}