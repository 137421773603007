$axone-gray:#777;
$warning-color: #750000;
$success-color: #007514;

@import "~bootstrap/scss/bootstrap";
@import "./Fonts.scss";
@import "./Animations.scss";


html{
    min-height:100%;
    background: #151515;
    box-shadow: inset 0 0 5rem rgba(0,0,0,.9);
}
body{
    background:transparent;
    font-family: "Gotham-Light", sans-serif;
    color: $axone-gray;
    min-height: 100vh;
}
a{
  color: lighten($axone-gray, 20%);
  font-weight:bold;
}
a:hover{
  color: lighten($axone-gray, 40%);
  text-decoration:none;
}
b{
  font-family: "Gotham-Bold", sans-serif;
}
#root, .fullscreen{
    min-height: 100vh;
}
section#notifications{
  position: fixed;
  bottom:1.75rem;
  right:.5rem;
}
.toast, .card{
  background:rgba(0,0,0,.85);
}
.toast-header, .card-header{
  color: $axone-gray;
  background:rgba(0,0,0,.95);
}
.toast-body{
  min-width: 14rem;
}
.close{
  color: $axone-gray;
  text-shadow:0 1px 0 rgba(0,0,0,.9);
}
.close:hover{
  color: lighten($axone-gray,20%);
}
.btn{
  min-width:30px;
}
.btn-primary{
    background:linear-gradient(to bottom, #b4b4b4 0%, #686868 100%);
    border:0;
    font-weight:bold;
}
.btn-primary:hover{
    background:linear-gradient(to bottom, #dedede 0%, #919191 100%);
}
.btn-primary:focus, .btn-primary.focus{
    border-color:lighten($axone-gray,20%);
    box-shadow: inset 0 0 1rem rgba(10,10,10,.9);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus{
    box-shadow: inset 0 0 1rem rgba(10,10,10,.9);
}
.custom-range::-moz-range-track, .custom-range::-webkit-slider-runnable-track{
  background: rgba($axone-gray,0.3);
}
.custom-range::-moz-range-thumb, .custom-range::-webkit-slider-thumb{
  background-color: $axone-gray;
}
.switch {cursor: pointer;position: relative;}
.switch input {position: absolute;opacity: 0;filter: alpha(opacity=0);}
.switch input:checked + span:after {left: 21px;}
.switch input:checked + span {background-color: #59AD2F;}
.switch input:disabled + span {background-color: #CCC;}
.switch span {position: relative;width: 50px;height: 30px;-moz-border-radius: 30px;-webkit-border-radius: 30px;border-radius: 30px;background-color: #AF2F2F;border: 1px solid #E5E5E5;display: inline-block;left: 0px;
  -webkit-transition: all 200ms ease; -moz-transition: all 200ms ease; -ms-transition: all 200ms ease; -o-transition: all 200ms ease; transition: all 200ms ease; border-color: rgba(0, 0, 0, 0.1);}
.switch span:after {content: "";position: absolute;background-color: #fff;width: 26px;top: 1px;bottom: 1px;left: 1px;-moz-border-radius: 30px;-webkit-border-radius: 30px;border-radius: 30px;-moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25); box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25); -webkit-transition: all 200ms ease; -moz-transition: all 200ms ease; -ms-transition: all 200ms ease; -o-transition: all 200ms ease; transition: all 200ms ease;}
.switch.switch-small {margin: 6px 0px 4px;}
.switch.switch-small input:checked + span:after {left: 11px;}
.switch.switch-small span {width: 30px;height: 20px;-moz-border-radius: 20px;-webkit-border-radius: 20px;border-radius: 20px;}
.switch.switch-small span:after {width: 16px;}
.form-control{
    border-color:$axone-gray;
    background: #111;
    color:lighten($axone-gray,50%);
    box-shadow: inset 0 0 1rem rgba(0,0,0,.9);
}
.form-control:focus {
    color:lighten($axone-gray,20%);
    background: #111;
    border-color:lighten($axone-gray,20%);
    outline: 0;
    box-shadow: inset 0 0 1rem rgba(10,10,10,.9);
}
.form-control:not(:placeholder-shown):invalid {
    border-color: $warning-color;
}
.form-control:disabled, .form-control[readonly] {
  background-color: inherit;
  opacity: .3;
}
.sticky-top{
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,.8) 100%);
    box-shadow: 0 .2rem .2rem -0 #111;
}
.alert{
    color: #fff;
    padding:.1rem .5rem;
    margin: 1rem .1rem;
    border:0;
    background-color: transparent;
    font-weight:bold;
}
.alert::before{
  content:'';
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: linear-gradient(45deg, lighten($warning-color,30%), darken($warning-color,5%));
  z-index:-1;
}
.alert::after{
  content:'';
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: linear-gradient(45deg, lighten($warning-color,30%), darken($warning-color,5%));
  z-index:-1;
  filter: blur(.65rem);
}
.alert-success::after, .alert-success::before{
  background: linear-gradient(45deg, lighten($success-color,30%), darken($success-color,5%));
}
.alert span{
  display:block;
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
  padding:.25rem .5rem;
  z-index:2;
  background: rgba(0,0,0,.85);
}
.alert span .alert-link{
  color: lighten($warning-color,30%);
}
.alert-success span .alert-link{
  color: lighten($success-color,30%);
}
footer{
    position: fixed;
    bottom:0;
    left:0;
    width:100%;
    background:linear-gradient(to bottom, #0003 0%, #000f 100%);
}
/*
 * Sidebar
 */

 .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 2rem 1rem;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 48px; /* Height of navbar */
    height: calc(100vh - 48px);
    padding-top: 2.5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: $axone-gray;
    transition: color .3s;
    transition-timing-function: ease-in-out;
  }
  .sidebar .nav-link:hover{
      color: lighten($axone-gray,30%);
  }

/*
 * Navbar
 */
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  .table {
    color: $axone-gray;
  }
  .table thead th{
    border-bottom: 1px solid $axone-gray;
    border-top: 0;
  }
  table.table td{
    border-top: 1px solid darken($axone-gray,30%);
  }
  .table tbody + tbody {
    border-top:0;
  }
  .table-hover tbody tr {
    transition: color .3s, background-color .2s;
    transition-timing-function: ease-in-out;
  }
  .table-hover tbody tr:hover{
    color:lighten($axone-gray,50%);
  }
  .table button{
    min-width:2rem;
  }
  .table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(0, 0, 0, 0.25);
}
table.table-striped tbody tr.table-spinner{
  background:transparent;
}
.table tr.table-spinner td{
  height:725px;
  vertical-align:middle;
}
.table .text-grey{
  color: $axone-gray;
}
.table tr.online, .table tr td .online{
  color: $success-color;
}
td.pointer{
  font-weight: bold;
  cursor: pointer;
}
.page-link{
  background-color: rgba(0, 0, 0, 0.25);
  color:lighten($axone-gray,20%);
  border: 1px solid darken($axone-gray,30%);
}
.page-link:hover{
  background-color: rgba(0, 0, 0, 0.15);
  color:lighten($axone-gray,50%);
  border: 1px solid darken($axone-gray,10%);
}
.page-item.disabled .page-link{
  background-color: rgba(0, 0, 0, 0.5);
  color:$axone-gray;
  border: 1px solid darken($axone-gray,30%);
}
.page-item.active .page-link{
  background-color:darken($axone-gray,10%);
  border: 1px solid darken($axone-gray,20%);
}
.btn-order-toggle{
  background: transparent;
  border: 0;
  font-family: "Gotham-Bold", sans-serif;
  font-weight:bold;
  color:$axone-gray;
}
.btn-order-toggle:hover{
  color:lighten($axone-gray,20%);
}
.btn-order-toggle::after{
  color:lighten($axone-gray,20%);
  margin-left:.5rem;
  font-size:.8rem;
}
.btn-order-toggle.asc::after{
  content: '▼';
}
.btn-order-toggle.desc::after{
  content: '▲';
}
#imprint p, #imprint h2, #imprint h3{
  min-width:30rem;
  text-align:center;
}
.modal-content{
  background-color: rgba(darken($axone-gray,30%),.8);
  color:$axone-gray;
  box-shadow: 0 0 10px 8px rgba(darken($axone-gray,35%), .8);
}
.modal-header{
  background-color: rgba(darken($axone-gray,35%),.9);
  border-bottom:0
}
.modal-footer{
  border-top:0;
}
.card.disabled::after{
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(#000,0.5)
}
.spacer12g{
  min-height:44px;
}
.inlines .form-control{
  display:inline-block;
  width: auto;
}
.form-control option[selected]{
  background:$axone-gray;
  font-weight:bold;
}